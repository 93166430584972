import React, {Suspense} from 'react';
import './App.css';
import {PreloadContext, PreloadData} from "../index";
import {AuthService, User} from "../services/auth-service";
import LoadingPage from "./LoadingPage";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

// Importing pages
const ActivityPage = React.lazy(() => import("./ActivityPage"));
const PacketPage = React.lazy(() => import("./PacketPage"));
const LoginPage = React.lazy(() => import("./auth/LoginPage"));
const RegisterPage = React.lazy(() => import("./auth/RegisterPage"));
const MainPage = React.lazy(() => import("./MainPage"));
const ProfilePage = React.lazy(() => import("./auth/ProfilePage"));
const PasswordResetPage = React.lazy(() => import("./auth/passwordReset/PasswordResetPage"));
const ForgotPasswordPage = React.lazy(() => import("./auth/passwordReset/ForgotPasswordPage"));
const AboutUsPage = React.lazy(() => import('./AboutUsPage'));

interface AppState {
    preloadData?: PreloadData,
    loading: boolean,
}

class App extends React.Component<any, AppState> {

    private userSubscriber;

    constructor(props: Readonly<any> | any) {
        super(props);
        this.state = {
            loading: false,
        }

        this.userSubscriber = AuthService.$userSubscription.subscribe((user) => {
            const preloadData = this.state.preloadData;
            if (preloadData) {
                preloadData.user = user;
                this.setState({
                    preloadData: preloadData,
                });
            }
        });
    }

    componentDidMount() {
        this.preloadData().then();
    }

    componentWillUnmount() {
        this.userSubscriber.dispose();
    }

    /**
     * Preloads all of the necessary app data, such as user information (if available)
     * @private
     */
    private async preloadData() {

        // Array of promises that will preload different sets of data
        const promises: Promise<any>[] = [
            AuthService.getUser(),
        ];

        const result = await Promise.all(promises);

        const user: User = result[0];

        this.setState({
            preloadData: {
                user: user,
            }
        });
    }

    render() {
        const {preloadData, loading} = this.state;

        if (!preloadData || loading) {
            return (<LoadingPage/>);
        }

        return (<PreloadContext.Provider value={preloadData}>
            <BrowserRouter>
                {/* Only shows the header if user is logged in */}
                {preloadData.user ? (
                    <Header preloadData={preloadData}/>
                ) : undefined}
                <main>
                    <Suspense fallback={<LoadingPage/>}>
                        <Switch>
                            <Route component={LoginPage} path="/login"/>
                            <Route component={RegisterPage} path="/register"/>
                            <Route path={"/forgot-password"} component={ForgotPasswordPage}/>
                            <Route path={"/reset-password/:code"} component={PasswordResetPage}/>

                            {/* The following logic handles protected routes */}
                            {preloadData.user ? (<Switch>
                                <Route component={ActivityPage} path="/activities"/>
                                <Route component={PacketPage} path="/packets"/>
                                <Route component={ProfilePage} path="/profile"/>
                                <Route component={AboutUsPage} path="/about-us"/>
                                <Route component={MainPage} path="/"/>
                            </Switch>) : (<Redirect to="/login"/>)}
                        </Switch>
                    </Suspense>
                </main>
                <Footer/>
            </BrowserRouter>
        </PreloadContext.Provider>);
    }

}

export default App;
