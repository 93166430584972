import axios from "axios";
import {BASE_URL} from "../index";
import {BehaviorSubject} from "rx";

export interface User {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    picture?: any,
    roleDescription: string,
    company: string,
}

interface RegisterData {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    roleDescription: string,
    picture?: File,
    company: string,
}

interface UpdateData {
    firstName: string;
    lastName: string;
    email: string;
    roleDescription: string;
    picture?: File;
    oldPassword?: string;
    password?: string;
    company: string;
}

interface LoginData {
    identifier: string,
    password: string
}

export class AuthService {

    static user: User | undefined = undefined;

    static $userSubscription: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);

    static getUser(): Promise<User | undefined> {
        return new Promise(async (resolve, reject) => {
            const jwt = localStorage.getItem('session_key');
            if (!jwt) return resolve(undefined);

            axios.get(BASE_URL + '/users/me', {
                headers: {'Authorization': 'Bearer ' + jwt,}
            }).then((res) => {
                if (!res.data) return resolve(undefined);

                axios.defaults.headers.Authorization = 'Bearer ' + jwt;

                const user = res.data;
                if (localStorage.getItem('profile_picture')) {
                    user.picture = {
                        formats: {
                            thumbnail: {
                                url: localStorage.getItem('profile_picture')
                            }
                        }
                    };
                }

                // Notify every listener that the user is set
                AuthService.$userSubscription.onNext(user);

                resolve(user);
            }).catch((err) => {
                // Removing session key because it is not valid
                localStorage.removeItem('session_key');
                localStorage.removeItem('profile_picture');

                // Notify every listener that user is undefined
                AuthService.$userSubscription.onNext(undefined);

                resolve(undefined);
            });
        });
    }

    static update(data: UpdateData): Promise<User> {
        const formData = new FormData();
        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('roleDescription', data.roleDescription);
        formData.append('email', data.email);
        formData.append('company', data.company);

        if (data.password && data.oldPassword) {
            formData.append('password', data.password);
            formData.append('oldPassword', data.oldPassword);
        }

        if (data.picture) {
            formData.append('picture', data.picture, data.picture.name);
        }

        return axios.put(BASE_URL + '/users/me', formData).then((res) => {
            AuthService.$userSubscription.onNext(res.data);
            return res.data;
        });
    }

    static login(loginData: LoginData): Promise<boolean> {
        return axios.post(BASE_URL + '/auth/local', loginData).then((res) => {
            if (res.data.jwt) localStorage.setItem('session_key', res.data.jwt);

            const user = res.data.user;
            if (user.picture) {
                localStorage.setItem('profile_picture', user.picture.formats.thumbnail.url);
            }

            if (res.data.user) AuthService.$userSubscription.onNext(user);

            return true;
        }).catch((err) => {
            console.error(err);
            return false;
        });
    }

    /**
     * Handles the logout by just removing the `session_key` from local storage and publish user as undefined
     */
    static logout() {
        localStorage.removeItem('session_key');
        localStorage.removeItem('profile_picture');

        AuthService.$userSubscription.onNext(undefined);
    }

    static register(registerData: RegisterData): Promise<{ user: User, jwt: string }> {
        const formData = new FormData();
        formData.append('firstName', registerData.firstName);
        formData.append('lastName', registerData.lastName);
        formData.append('email', registerData.email);
        formData.append('password', registerData.password);
        formData.append('roleDescription', registerData.roleDescription);
        formData.append('company', registerData.company);

        if (registerData.picture) formData.append('picture', registerData.picture, registerData.picture.name);

        return axios.post(BASE_URL + '/auth/local/register', formData)
            .then((res) => ({
                jwt: res.data.jwt,
                user: res.data.user
            }));
    }

    static getProfilePicture(user: User) {
        if (user && user.picture) return BASE_URL + user.picture.formats.thumbnail.url;

        return '/img/profile.jpg';
    }

    static resetPassword(code: string, password: string, passwordConfirmation: string): Promise<void> {
        return axios.post(BASE_URL + '/auth/reset-password', {
            code: code,
            password: password,
            passwordConfirmation: passwordConfirmation
        });
    }

    static forgotPassword(email: string): Promise<void> {
        return axios.post(BASE_URL + '/auth/forgot-password', {
            email: email,
        });
    }
}