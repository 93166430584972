import logo from "../logo_white.png";

function Footer() {
    return (
        <div id="footer">
            <h3 className="text-white text-center mb-3">
                Det här är en webbplats där vi i Visit Karlstad tillsammans skapar ett Karlstad som tar hand om sina
                besökare.
            </h3>
            <div className="d-flex">
                <img src={logo} alt={"Logo"}/>
                <p className="text-white mb-0 ml-4">
                    <a href={"https://www.visitkarlstad.se/"}>visitkarlstad.se</a> <br/>
                    <a href={"mailto:hanna@riverc.se"}>hanna@riverc.se</a>
                </p>
            </div>
        </div>
    );
}

export default Footer;