import React, {Component} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {BASE_URL, PreloadData} from "../../index";
import {AuthService} from "../../services/auth-service";

// Importing assets
import logo from "../../logo.png";
import "./Header.scss";

interface HeaderProps {
    preloadData: PreloadData;
}

const links = [
    {
        name: 'Hem',
        url: '/home',
    },
    {
        name: 'Vad är det här?',
        url: '/about-us'
    },
    {
        name: 'Solglimtar',
        url: '/activities'
    },
    {
        name: 'Solpaket',
        url: '/packets'
    }
];

class Header extends Component<any, HeaderProps> {

    render() {

        const {preloadData} = this.props;

        const handleLogout = () => {
            AuthService.logout();
        }

        return (<Navbar className="bg-white" expand="lg" variant="light">
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo} alt="Karlstad Logo"/>
                </Navbar.Brand>
                {links.map(link => (
                    <Nav.Link key={'link-' + link.url} href={link.url}>{link.name}</Nav.Link>)
                )}
                <Nav className="ml-auto d-flex align-items-center">
                    {preloadData.user ? (<>
                        <Nav.Link href="/profile">
                            <div
                                className="profile-picture"
                                style={{
                                    backgroundImage: `url("${AuthService.getProfilePicture(preloadData.user)}")`
                                }}
                            />
                        </Nav.Link>
                        <Nav.Link className="profile"
                                  href="/profile">{preloadData.user.firstName} {preloadData.user.lastName}</Nav.Link>
                        <a href="#" className="ml-4 nav-link" id="logout" onClick={handleLogout}>Logga ut</a>
                    </>) : (<>
                        <Nav.Link href="/login" className="btn btn-primary text-white mr-4">Logga In</Nav.Link>
                        <Nav.Link href="/register">Registrera</Nav.Link>
                    </>)}

                </Nav>
            </Container>
        </Navbar>);
    }

}

export default Header;