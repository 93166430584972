import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {User} from "./services/auth-service";

/**
 * The global backend-url definition
 */
export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:1337';

/**
 * Is used to provide the PreloadData object to other components
 */
export const PreloadContext = React.createContext<PreloadData>({});

/**
 * Describes the data which is expected when preloading the page
 */
export interface PreloadData {
    user?: User,
}

ReactDOM.render(
    <>
        <App/>
    </>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();